import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG } from "../components/functions"
import Breadcrumbs from "../components/common/breadcrumbs/breadcrumbs"
import { HOTEL_TAXONOMY_VERANDA_RESORTS_ID } from "../constants"

export default function Gallery({
  data: {
    nodeGallery: gallery,
    allTaxonomyTermGalleryCategory: categories,
    allTaxonomyTermHotels: hotels,
    allNodeGalleryItem
  },
  pageContext,
}) {
  const isSSR = typeof window === "undefined"
  const BlockGallery = React.lazy(() =>
    import("../components/common/blockGallery/blockGallery")
  )
  const MainBannerImage = React.lazy(() =>
    import("../components/common/mainBanner/mainBannerImage")
  )

  const {
    title,
    field_seo,
    relationships: {
      node__gallery_item: galleryItems,
      gallery_carousel: {
        relationships: { carousel_highlight_item: carouselMea },
      },
    },
  } = gallery

  const intl = useIntl()

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  return (
    <Layout pageContext={pageContext} pageTitle={title}>
      <Seo data={seoData} pageContext={pageContext} />
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          {carouselFiltered && <MainBannerImage items={carouselFiltered} />}
          <Breadcrumbs
            hotelTaxonomyDrupalId={pageContext.hotelTaxonomyDrupalId}
            pageTitle={title}
          />
          {galleryItems && (
            <BlockGallery
              categories={categories}
              //hotels={hotels}
              items={galleryItems}
            />
          )}
          {pageContext.hotelTaxonomyDrupalId === HOTEL_TAXONOMY_VERANDA_RESORTS_ID &&
            <BlockGallery
              categories={categories}
              hotels={hotels}
              items={allNodeGalleryItem.nodes}
            />
          }
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $language: String!) {
    nodeGallery(id: { eq: $id }) {
      ...galleryFields
    }
    allTaxonomyTermGalleryCategory(
      filter: { name: { ne: "Dummy" }, langcode: { eq: $language } }
    ) {
      nodes {
        ...allGalleryCategoryFields
      }
    }
    allNodeGalleryItem(
      filter: { title: { ne: "Dummy" }, langcode: { eq: $language } }
      sort: {order: ASC, fields: field_weight}
    ) {
      nodes {
        title
        id
        drupal_internal__nid
        field_weight
        relationships {
          gallery_item_media {
            ... on media__image {
              field_image {
                alt
              }
              internal {
                type
              }
              relationships {
                field_image {
                  gatsbyImage(breakpoints: [800], width: 800, formats: WEBP, layout: FULL_WIDTH)
                  galleryOriginal: gatsbyImage(breakpoints: [1920], width: 1920, formats: JPG, layout: FULL_WIDTH)
                  galleryThumb: gatsbyImage(breakpoints: [800], width: 800, formats: WEBP, layout: FULL_WIDTH)
                }
              }
            }
            ... on media__wistia {
              internal {
                type
              }
              field_media_oembed_video
              relationships {
                thumbnail {
                  gatsbyImage(width: 1920, formats: WEBP)
                }
              }
            }
          }
          gallery_item_category {
            name
            id
          }
          gallery_item_hotel {
            name
            id
          }
        }
      }
    }
    allTaxonomyTermHotels(
      filter: { name: { ne: "Dummy" }, langcode: { eq: $language } }
      sort: { fields: weight }
    ) {
      nodes {
        ...allGalleryHotelsFields
      }
    }
  }
`
